import { MODULE } from "../../config/routes/RoleProtectedRoute";
import User from '../../assets/icons/User.svg'
import bussinessDetails from '../../assets/icons/Shape.svg'
import Image from '../../assets/icons/Image.svg'
import Content from '../../assets/icons/Content Discount.svg'
import DashIcon from '../../assets/icons/Dashboard.svg'
import LocationIcon from '../../assets/icons/Location.svg'
import Coin from '../../assets/icons/Coin.svg'

// active icon
import ActUser from '../../assets/icons/active-icons/User.svg'
import ActbussinessDetails from '../../assets/icons/active-icons/Shape.svg'
import ActImage from '../../assets/icons/active-icons/Image.svg'
import ActContent from '../../assets/icons/active-icons/Content Discount.svg'
import ActLocationIcon from '../../assets/icons/active-icons/Location.svg'
import ActDashIcon from '../../assets/icons/active-icons/Dashboard.svg'
import PaymentIcon from '../../assets/icons/Payment.svg';

export const sidepanalLinks = [
  {
    Title: "Dashboard",
    icons: DashIcon,
    acticon: ActDashIcon,
    Link: "/dashboard",
    module: MODULE.BUSINESS,
  },
  {
    Title: "Business Profile",
    icons: bussinessDetails,
    acticon: ActbussinessDetails,
    Link: "/dashboard/edit-business-details",
    module: MODULE.BUSINESS,
  },
  {
    Title: "Subscription & Billing",
    Link: "/dashboard/subold",
    icons: PaymentIcon,
    acticon: ActUser,
    module: MODULE.BUSINESS,
  },
  {
    Title: "Team Management",
    Link: "/dashboard/business-user",
    icons: User,
    acticon: ActUser,
    module: MODULE.BUSINESS,
  },
  // {
  //   Title: "Followers",
  //   Link: "#",
  //   icons: User,
  //   acticon: ActUser,
  //   module: MODULE.BUSINESS,
  // },
  {
    Title: "Content Management",
    Link: "/dashboard/content",
    icons: Content,
    acticon: ActContent,
    module: MODULE.BUSINESS,
  },
  // {
  //   Title: "Image Management",
  //   Link: "/dashboard/image-gallery",
  //   icons: Image,
  //   acticon: ActImage,
  //   module: MODULE.BUSINESS,
  // },
  {
    Title: "Locations Management",
    Link: "/dashboard/location",
    icons: LocationIcon,
    acticon: ActLocationIcon,
    module: MODULE.BUSINESS,
  },
  // {
  //   Title: "Social Media Accounts",
  //   Link: "/dashboard/socialmedia",
  //   icons: Coin,
  //   acticon: Coin,
  //   module: MODULE.BUSINESS,
  // },
  {
    Title: "Contact Us",
    Link: "mailto:support@pinntag.com",
    icons: Coin,
    acticon: Coin,
    module: MODULE.BUSINESS,
  },
  {
    Title: "Terms & Conditions",
    Link: "https://pinntag.com/terms-conditions/",
    target: "_blank",
    icons: Coin,
    acticon: Coin,
    module: MODULE.BUSINESS,
  },
  {
    Title: "Privacy Policy",
    Link: "https://pinntag.com/privacy-policy/",
    target: "_blank",
    icons: Coin,
    acticon: Coin,
    module: MODULE.BUSINESS,
  },
  {
    Title: "My Businesses",
    Link: "/dashboard/business-details",
    icons: DashIcon,
    acticon: ActDashIcon,
    module: MODULE.USER,
  },
  {
    Title: "User Profile",
    Link: "/dashboard/edit-user",
    icons: User,
    acticon: ActUser,
    module: MODULE.USER,
  },
  {
    Title: "Terms & Conditions",
    Link: "https://pinntag.com/terms-conditions/",
    target: "_blank",
    icons: Coin,
    acticon: Coin,
    module: MODULE.USER,
  },
  {
    Title: "Privacy Policy",
    Link: "https://pinntag.com/privacy-policy/",
    target: "_blank",
    icons: Coin,
    acticon: Coin,
    module: MODULE.USER,
  },
];

export const status = {
  active: "Active",
  inActive: "Inactive",
  scheduled: "Scheduled",
};

export const socialMediaPost = [
  {
    status: status.active,
    discription: "Free Coffee with Breakfast Entre",
    image:
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    status: status.active,
    discription: "Free Coffee with Breakfast Entre",
    image:
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    status: status.scheduled,
    discription: "Free Coffee with Breakfast Entre",
    image:
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    status: status.inActive,
    discription: "Free Coffee with Breakfast Entre",
    image:
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    status: status.active,
    discription: "Free Coffee with Breakfast Entre",
    image:
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    status: status.active,
    discription: "Free Coffee with Breakfast Entre",
    image:
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    status: status.active,
    discription: "Free Coffee with Breakfast Entre",
    image:
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    status: status.active,
    discription: "Free Coffee with Breakfast Entre",
    image:
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    status: status.active,
    discription: "Free Coffee with Breakfast Entre",
    image:
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    status: status.active,
    discription: "Free Coffee with Breakfast Entre",
    image:
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    status: status.active,
    discription: "Free Coffee with Breakfast Entre",
    image:
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    status: status.active,
    discription: "Free Coffee with Breakfast Entre",
    image:
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    status: status.active,
    discription: "Free Coffee with Breakfast Entre",
    image:
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    status: status.active,
    discription: "Free Coffee with Breakfast Entre",
    image:
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    status: status.active,
    discription: "Free Coffee with Breakfast Entre",
    image:
      "https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
];
