import React, { useEffect, useState } from "react";
import { socialMediaPost } from "../../contentmanagement/sidepanal";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { ReactComponent as Copyicon } from '../../assets/icons/copyicon.svg'
import { ReactComponent as Deleteicon } from '../../assets/icons/delete.svg'
import { ReactComponent as Editicon } from '../../assets/icons/Edit.svg'
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Image from "../../component/image";
import Text from "../../common/Text";
import { Link, useSearchParams } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import ContentLoader from "../../common/Loader/contentLoader";
import { getData, getDataTemp } from "../../utils/api";
import moment from "moment/moment";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularImage from "../../common/UiElements/CircularImage";

const FilterContentData = ({ data }) => {
  return (
    <>
      {data?.map((items) => (
        <div key={items?.id} className="relative flex flex-col items-center justify-center overflow-hidden">
          <div className="w-full flex items-center gap-2 p-1.5 border-b pb-3 border-gray-200 bg-white">
            <div className="flex-shrink-0 w-[45px] h-[45px]">
              <CircularImage
                src={items?.targetUser?.profilePhoto}
                alt={items?.targetUser?.name}
              />
            </div>
            <div>
              <Text className="font-medium text-base text-black leading-5">
                {items?.message}
              </Text>
              <Text className="font-sm text-sm text-black leading-5">
                {moment(items.createdAt).format('MMMM Do YYYY, h:mm a')}
              </Text>
            </div>
            {/* <ChevronRightIcon className="w-[40px] h-[40px]" /> */}
          </div>
        </div>
      ))}
    </>
  );
};

const Notifications = () => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [data, setData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const handleChangeItemsPerPage = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to first page after changing items per page
  };

  const fetchAllNotification = async (query) => {
    setLoading(true);
    try {
      const res = await getData("notification/all");
      if (res.data) {
        const datares = res?.data?.notifications;
        setData(datares);
      } else {
        console.log(res, "Error while fetching notification");
      }
    } catch (error) {
      console.error("Error while fetching notification:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllNotification();
  }, []);

  return (
    <div className="mt-[2.7rem] flex flex-col h-full md:max-w-lg p-3 mb-2 rounded-md mx-auto border border-gray-200">
      <div>
        {loading ? (
          <ContentLoader />
        ) : data ? (
          <>
            <div className="gap-2 flex flex-col contant-res-design">
              <FilterContentData data={data} />
            </div>
          </>
        ) : (
          <>
            <div className="mt-10 text-center text-[#666] font-semibold text-lg">
              <p>No Notifications!</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Notifications;
